/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx, Styled, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import { baseColors } from "@theme-ui/preset-tailwind"
import { SEO } from "gatsby-theme-swhwsys-core"

import { Link, graphql } from 'gatsby';

interface NotFoundPageProps {
	data: ReactNode;
}

function NotFoundPage({ data }: NotFoundPageProps) {
	return (
		<HomeLayout>
			<SEO
				title="Not Found"
				description="Software Hardware Systems Page not found"
			/>
			<Styled.h1>{data.site.siteMetadata.notFound.consternation}</Styled.h1>
			<Styled.h2>{data.site.siteMetadata.notFound.condolences.expression1}</Styled.h2>
			<Styled.h3>{data.site.siteMetadata.notFound.condolences.expression2}</Styled.h3>

      <div sx={{ display: "grid", placeItems: "center" }}>
        <Button
          as={Link}
          sx={{
            backgroundImage:
              "radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,193,104,1) 0%, rgba(251,128,128,1) 90% )",
            color: baseColors.gray[8],
            fontWeight: "bold",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage:
                "radial-gradient( circle farthest-corner at 30% 40%,  rgba(253,193,80,1) 0%, rgba(251,128,100,1) 95% )",
            },
          }}
          to="/"
        >
          Home
        </Button>
      </div>

		</HomeLayout>
	);
}

export default NotFoundPage;

export const query = graphql`
	query NotFoundQuery {
		site(siteMetadata: {}) {
			siteMetadata {
				notFound {
					consternation
					condolences {
						expression1
						expression2
					}
				}
			}
		}
	}
`;
